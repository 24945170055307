import React, { useState, Component } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { theme } from '../styles'

import brick from '../images/brick-red-text.png'
import brickIcon from '../images/brick-red.png'
import Burger from './Burger'
import Menu from './Menu'

const StyledHeader = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 2em;
`

const StyledHome = styled(Link)`
  color: ${theme.colors.grey};
  font-style: normal;

  :hover,
  :focus {
    text-decoration: none;
    outline: none;
  }

  img {
    width: 3em;
  }
`

const StyledEthos = styled.div`
  color: ${theme.colors.grey};

  padding: 1em;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`

function Header(props) {
  const [open, setOpen] = useState(false)

  return (
    <StyledHeader>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
      <StyledHome to="/">
        <img src={brickIcon} alt="brick-logo" />
      </StyledHome>
      <StyledEthos>{props.ethos}</StyledEthos>
    </StyledHeader>
  )
}

export default Header
