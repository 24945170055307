import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../styles'

const StyledCat = styled.div`
  background-color: ${theme.colors.grey};
  color: white;
`

const StyledCats = styled.div`
  display: grid;
`

const Categories = ({ cats, ...rest }) => (
  <StyledCats>
    {cats.map(c => (
      <StyledCat>{c}</StyledCat>
    ))}
  </StyledCats>
)

export default Categories
