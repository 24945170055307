import React from 'react'
import styled from '@emotion/styled'
import Subheadline from './Subheadline'

import { theme } from '../styles'

const StyledTitle = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 2em;
  text-align: center;

  div {
    width: 40%;
    height: 2px;
    background-color: ${theme.colors.grey};
    align-self: start;
    margin-bottom: 1em;
  }

  h2 {
    margin-bottom: 0.2em;
  }
`

const StyledRecent = styled.div`
  padding: 1em;
`

const StyledPosts = styled.div`
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  grid-gap: 1em;

  @media screen and (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Recent = ({ headlineId, posts, ...rest }) => (
  <StyledRecent>
    <StyledTitle>
      <h2>RECENT POSTS</h2>
      <div />
    </StyledTitle>
    <StyledPosts>
      {posts
        .filter(p => p.node.prismicId != headlineId)
        .map(p => (
          <Subheadline uid={p.node.uid} post={p.node.data} />
        ))}
    </StyledPosts>
  </StyledRecent>
)

export default Recent
