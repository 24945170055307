import React from 'react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { theme } from '../styles'

const StyledLink = styled(Link)`
  font-style: normal;
  text-decoration: none;
  outline: 0;
  width: 100%;

  &:hover {
    text-decoration: none;
    outline: 0;
    color: inherit;
  }
`

const StyledSubheadline = styled.div`
    display: grid;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.2);
  font-weight: 500;
  color: ${theme.colors.greyDark};

  img {
    width: 100%;
  }

  @media screen and (max-width: ${theme.breakpoints.m}) {
    min-height: 50px;
    grid-template-columns: 2fr 3fr;
    overflow: hidden;

    p {
    display: none;
    }
  }
`

const StyledText = styled.div`
    display: grid;
    align-items: center;

    padding: 0 .7em;
    margin-top: 10px;

    p {
    font-size: 0.8em;
    margin: 1em 0;
    }

    h3 {
    font-size: 1.2em;
    margin: 0;
    }

`

const Subheadline = ({ uid, post, ...rest }) => (
  <StyledLink to={`/articles/${uid}`}>
    <StyledSubheadline>
      <img src={post.featured_image.url} alt={post.featured_image.alt} />
      <StyledText>
        <h3>{post.title.text}</h3>
        {post.brief ? <p>{post.brief.text}...</p> : ''}
      </StyledText>
    </StyledSubheadline>
  </StyledLink>
)

export default Subheadline
