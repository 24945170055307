import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'

import facebook from '../images/facebook-white.png'
import twitter from '../images/twitter-white.png'
import instagram from '../images/instagram.svg'

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.greyDark};
  margin: 6rem auto 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.greyLight};

  display: grid;
  justify-items: center;
`

const Social = styled.a`
  margin: 1.3em;
  outline: 0;
  img {
    width: 2em;
  }
`

const Socials = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 5px;
`

const Copyright = styled.div`
  font-size: 0.7em;
  color: ${theme.colors.greyLight};
  margin: 10px;
`

const StyledContact = styled.div`
  border: 2px solid #fff;
  color: white;
  background-color: ${theme.colors.greyDark};

  a {
    color: inherit;
    padding: 0.3em 0.7em;
    text-decoration: none;
    font-size: 0.8em;
    font-style: normal;
    outline: 0;
    &:hover {
    }
  }
`

const Contact = props => (
  <StyledContact>
    <a href={props.url}>{props.text}</a>
  </StyledContact>
)

class Footer extends Component {
  render() {
    const { children } = this.props
    return (
        <StyledFooter>
            <Socials>
                <Social href="https://www.instagram.com/bricksussex/" target="_blank">
                    <img src={instagram} alt="instagram social" />
                </Social>
                <Social href="https://www.facebook.com/BRICKSUSSEX/" target="_blank">
                    <img src={facebook} alt="facebook social" />
                </Social>
                <Social href="https://twitter.com/bricksussex" target="_blank">
                    <img src={twitter} alt="twitter social" />
                </Social>
            </Socials>
            <Copyright>© 2019 BrickSussex</Copyright>
        </StyledFooter>
    )
  }
}

export default Footer

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}
