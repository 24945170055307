import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { theme } from '../styles'
import Categories from './Categories'

const StyledLink = styled(Link)`
  font-style: normal;
  text-decoration: none;
  outline: 0;

  &:hover {
    text-decoration: none;
    outline: 0;
  }
`

const StyledHeadline = styled.div`
  color: ${theme.colors.greyDark};
  box-shadow: 0 0 15px 0 rgb(0, 0, 0, 0.2);

  img {
    width: 100%;
  }
`

const StyledHeadlineText = styled.div`
  padding: 1em;
  p {
    font-weight: 500;
  }
`

const Headline = ({ uid, post, ...rest }) => (
  <StyledLink to={`/articles/${uid}`}>
    <StyledHeadline>
      <img src={post.featured_image.url} alt={post.featured_image.alt} />
      <StyledHeadlineText>
        <h2>{post.title.text}</h2>
        <p>{post.brief.text.slice(0, 150)}...</p>
      </StyledHeadlineText>
    </StyledHeadline>
  </StyledLink>
)

export default Headline
