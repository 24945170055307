import React, { Component } from 'react'
import { bool } from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../styles'

import brick from '../images/brick.png'

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.accent};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 5;

  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    font-style: normal;
    font-family: Sulphur Point, serif;
    text-transform: uppercase;
    margin: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${theme.colors.bg};
    text-decoration: none;
    transition: color 0.3s linear;
    outline: 0;

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: black;
    }
  }

  img {
    width: 50px;
    padding-top: 10em;
  }

  transform: ${props => (props.open ? 'translateY(0)' : 'translateY(-100%)')};
`

const Menu = ({ open }) => (
  <StyledMenu open={open}>
    <Link to="/">NEWS</Link>
    <Link to="/about">ABOUT</Link>
  </StyledMenu>
)

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
